import {ACTION_TYPE, getActionObj} from '../../../../utils/ActionsUtils'
import {getValue, isEmpty} from '../../../../utils/GeneralUtils'

import Button from '../../base/Button'
import Div from '../../base/Div'
import Anchor from '../../base/Anchor'
import ExpandableText from '../../base/text/ExpandableText'
import Image from '../../base/Image'
import PopupActionButton from '../PopupActionButton'
import UI from '../../base/UI'

function getActionButton(uiData = null, feature = null, callback = null) {
  const containerUI = Div({classNames: 'action-buttons'})

  if (!isEmpty(uiData.cta)) {
    const action = uiData.cta
    const button = PopupActionButton({
      id: action.id,
      classNames: action.style,
      label: action.label,
      disabled: action.disabled,
      onClick: (event) => {
        callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, event.target.id))
      }
    })
    UI.add(containerUI, button)
    return containerUI
  }

  return null
}

function PopupSections(uiData = null, feature = null, callback = null) {
  function getImageUI() {
    const containerUI = Div({classNames: 'image row'})
    const imgContainerUI = Div({classNames: 'image cell'})
    const image = getValue(() => uiData.image, null)
    if (!isEmpty(image)) {
      const imageUI = Image({
        classNames: 'site_image', src: image.imageURL, alt: image.alt, title: image.title
      })
      UI.add(imgContainerUI, imageUI)
    }
    const btnContainerUI = Div({classNames: 'btn cell'})
    if (!isEmpty(uiData.itemAvailabilityStatus)) {
      let displayContent = uiData.itemAvailabilityStatus;
      let classNamesForDis = 'avstatus ';
      if( !isEmpty(uiData.itemAvailabilityDisplayStatus) ){
        displayContent = uiData.itemAvailabilityDisplayStatus;
        classNamesForDis += 'enhance_info ';
      }
      const avstatus = Div({classNames: classNamesForDis, content: displayContent})
      UI.add(btnContainerUI, avstatus)
    }
    const btns = getActionButton(uiData, feature, callback)
    if (!isEmpty(btns)) {
      UI.add(btnContainerUI, btns)
    }
    if (!isEmpty(uiData.description)) {
      const descriptionUI = ExpandableText({
        text: uiData.description,
        textClassNames: isEmpty(uiData.textClassNames) ? '' : uiData.textClassNames,
        anchorClassnames: isEmpty(uiData.anchorClassnames) ? '' : uiData.anchorClassnames,
        maxChars: isEmpty(uiData.maxChars) ? 100 : uiData.maxChars,
        revealLabel: isEmpty(uiData.revealLabel) ? ' Show More' : uiData.revealLabel,
        hideLabel: isEmpty(uiData.hideLabel) ? ' Show Less' : uiData.hideLabel,
        expanded: isEmpty(uiData.expanded) ? false : uiData.expanded
      })
      if(uiData.descTitle){  
        UI.add(btnContainerUI, Div({id: 'descTitle', content: uiData.descTitle}))
      }
      UI.add(btnContainerUI, descriptionUI)
    }
    if (!isEmpty(uiData.selfRegText)) {
      const selfRegInfoContainerUI = Div({classNames: 'selfRegInfo'})
      UI.add(selfRegInfoContainerUI, Div({classNames: isEmpty(uiData.selfRegIcon) ? 'fa fa-circle-info' : uiData.selfRegIcon}))
      UI.add(selfRegInfoContainerUI, Div({classNames: 'selfRegText', content: uiData.selfRegText}))
      UI.add(btnContainerUI, selfRegInfoContainerUI)
    }
    if (!isEmpty(uiData.coords)) {
      const coords = Div({classNames: 'coords', content: uiData.coords})
      if(uiData.descTitle){
        UI.add(btnContainerUI, Div({id: 'coordsTitle', content: 'Longitude and Latitude'}))
      }
      UI.add(btnContainerUI, coords)
    }
    const clearDiv = Div({classNames: 'clear'})
    UI.add(containerUI, imgContainerUI)
    UI.add(containerUI, btnContainerUI)
    UI.add(containerUI, clearDiv)
    return containerUI
  }

  function getHeadUI() {
    const containerUI = Div({classNames: 'head row'})
    const head = getValue(() => uiData.head, null)

    if (!isEmpty(head)) {
      const accessible = head.accessible === 'true'
      const siteNameContainerUI = Div({classNames: 'site_name_container'})
      if (accessible) {
        const accessibleUI = Image({
          classNames: 'site_accessible', src: '/gis-maps/assets/icons/dots/handicapAccess.png', alt: 'Accessible', title: 'Accessible'
        })
        UI.add(siteNameContainerUI, accessibleUI)
      }
      const siteNameUI = Div({classNames: 'site_name', content: uiData.customizedName || head.siteName})
      UI.add(siteNameContainerUI, siteNameUI)
      UI.add(containerUI, siteNameContainerUI)
      if (!isEmpty(head.loop) || !isEmpty(head.siteType)) {
        const siteLoopUI = Div({classNames: 'site_loop', content: `${head.loop}, ${head.siteType}`})
        UI.add(containerUI, siteLoopUI)
      }
    }
    return containerUI
  }

  function getSectionsUI() {
    const containerUI = Div({classNames: 'section row'})

    const sections = getValue(() => uiData.sections, null)

    if (!isEmpty(sections)) {
      sections.forEach((section) => {
        const attributes = getValue(() => section.attributes, null)
        if (!isEmpty(attributes)) {
          if(uiData.customizedName){
            var showMore = Anchor({content: '<i class="far fa-plus-circle"></i>Show more',id:'attributeShowMore',
            onClick:function(){
                var more = document.querySelector('.plSites-popup.additionalPopupStyle #attributeShowMore');
                if(more){
                  more.style.display = 'none';
                }
                var attr = document.querySelector('.plSites-popup.additionalPopupStyle div.attributes');
                if(attr){
                  attr.style.display = 'flex';
                }
                var less = document.querySelector('.plSites-popup.additionalPopupStyle #attributeShowLess'); 
                if(less){
                  less.style.display = 'block';
                }
              }
            }); 
            UI.add(containerUI, showMore);
          }
          const attrCol = Div({classNames: 'attributes'})
          attributes.forEach((attribute) => {
            const attributeUI = Div({content: `<span class="small">${attribute.title}</span> <span class="bold">${attribute.value}</span>`})
            UI.add(attrCol, attributeUI)
          })
          UI.add(containerUI, attrCol)
        }
        if(uiData.customizedName){
          var showLess = Anchor({content: '<i class="far fa-minus-circle"></i>Show less',id:'attributeShowLess',
          onClick: function(){
              var more = document.querySelector('.plSites-popup.additionalPopupStyle #attributeShowMore');
              if(more){
                more.style.display = 'block';
              }
              var attr = document.querySelector('.plSites-popup.additionalPopupStyle div.attributes');
              if(attr){
                attr.style.display = 'none';
              }
              var less = document.querySelector('.plSites-popup.additionalPopupStyle #attributeShowLess'); 
              if(less){
                less.style.display = 'none';
              }
            }
          });
          UI.add(containerUI, showLess);
        }

      })
    }

    return containerUI
  }
  function getView360PhotoLink() {
    const containerUI = Div({})
    if (!isEmpty(uiData.image360) && uiData.image360.showLink) {
      const anchor = Button({
        id: uiData.image360.id,
        label: '360\u00B0 Photo',
        classNames: 'view_360_photo',
        onClick: (event) => {
          callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, event.target.id))
        }
      })
      UI.add(containerUI, anchor)
    }
    return containerUI
  }
  function getMainUI() {
    const mainContainer = Div({})
    const headUI = getHeadUI()
    if (!isEmpty(headUI)) {
      UI.add(mainContainer, headUI)
    }
    const imageUI = getImageUI()
    if (!isEmpty(imageUI)) {
      UI.add(mainContainer, imageUI)
    }
    const photo360LinkUI = getView360PhotoLink()
    if (!isEmpty(photo360LinkUI)) {
      UI.add(mainContainer, photo360LinkUI)
    }

    const sectionsUI = getSectionsUI()
    if (!isEmpty(sectionsUI)) {
      UI.add(mainContainer, sectionsUI)
    }

    return mainContainer
  }
  return getMainUI()
}

export default PopupSections
