/**
 * @file   : MapboxMapUtils.js
 * @author : Amarjeet Lamba (amarjeet.lamba@aspiraconnect.com)
 * @date   : 8/19/2020, 11:49:25 AM
 */
/** ****************************************
 *                                         *
 *              FRAMEWORK                  *
 *  (Follow Framework Editing Guidelines)  *
 *          (See Readme Files)             *
 *                                         *
 ***************************************** */

/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */

import 'mapbox-gl/dist/mapbox-gl.css'

import mapboxgl from 'mapbox-gl'
import {ACTION_TYPE, getActionObj} from '../utils/ActionsUtils'
import {
  STYLE_TYPE,
  getPopupContentByStyle,
  getShapePopupContentByStyle,
  isPopupRenderable
} from '../utils/MapStyleUtil'
import {
  findObjInArray,
  getValue,
  isEmpty,
  isUndefined,
  log
} from '../utils/GeneralUtils'

import Config from '../config/Config'
import {getDiv} from '../uiBuilders/UIUtil'

export const MARKER_TYPE_WITH_CUSTOMIZED_ICON = ['site', 'amenity', 'slip']

const LAYERS = {
  SHAPES: 'shapes',
  SHAPES_LINE: 'shapesLine',
  SHAPES_POLYGON: 'shapesPolygon',
  MARKERS_LABELS: 'markersLabels',
  CLUSTERS: 'clusters',
  CLUSTER_COUNT: 'cluster-count',
  UNCLUSTERED_POINT: 'unclustered-point',
  UNCLUSTERED_POINT_BG: 'unclustered-point-bg',
  UNCLUSTERED_POINT_HIGHLIGHT_BG: 'unclustered-point-highlight-bg',
  UNCLUSTERED_POINT_CORNER_MARKER: 'unclustered-point-corner-marker',
  UNCLUSTERED_POINT_CORNER_MARKER_BG: 'unclustered-point-corner-marker-bg',
  SKY: 'sky'
}

const SOURCES = {
  PERSPRCTIVE: 'src-perspective'
}

const CENTER_MAP_TYPES = {
  MAIN_VIEW: 'mainView',
  CENTER: 'center',
  FIT: 'fit',
  NONE: 'none'
}

export const HIGHLIGHT_STYLES = {
  POPUP: 'popup',
  BG_CIRCLE: 'bgCircle',
  POPUP_AND_BG_CIRCLE: 'popupAndBGCircle'
}

const mapData = {
  currentHighlightFeatures: null,
  showingPerspective: false,
  currentPopup: null,
  currentHoverPopup: null,
  lastCenterMapConfig: {},
  hoveredFeature: null
}

const markerImages = []

export function createMapAndDrawData(
  containerId,
  clientCallback,
  data,
  mainView,
  mapActionsCompleteCallback
) {
  const mapStyle = Config.get().getMapStyle(Config.get().getDefaultMapStyleId())
  mapboxgl.accessToken = Config.get().getProviderConfig().accessToken
  const center = getCenter(data, Config.get().getCenterMapConfig())
  const zoom = getZoom(Config.get().getCenterMapConfig())
  const minZoom = Config.get().getZoomConfig().min
  const maxZoom = Config.get().getZoomConfig().max
  const {preserveDrawingBuffer} = Config.get().getMapTypeConfig()
  // log('center', center)
  // log('zoom', zoom)

  // fix AWO-227436, init map with bounds if applicable
  let initMapBoundsVal = getMapInitBoundsVal(data);

  const map = new mapboxgl.Map({
    container: containerId,
    style: mapStyle.vectorStyle,
    zoom,
    center,
    minZoom,
    maxZoom,
    bounds: initMapBoundsVal.boundsVal,
    fitBoundsOptions: initMapBoundsVal.fitBoundsOptionsVal,
    preserveDrawingBuffer
  })

  map.on('load', () => {
    // map.on('mouseenter', LAYERS.UNCLUSTERED_POINT, () => {
    //   map.getCanvas().style.cursor = 'pointer'
    // })
    // map.on('mouseleave', LAYERS.UNCLUSTERED_POINT, () => {
    //   map.getCanvas().style.cursor = ''
    // })
    const uiOptions = Config.get().getUIOptionsConfig()
    if (uiOptions.navigationControl.active) {
      map.addControl(new mapboxgl.NavigationControl())
    }
    if (uiOptions.scaleControl.active) {
      // mapboxgl.ScaleControl.prototype.onAdd = function (map) {
      //   this._map = map
      //   if (uiOptions.mapStyle.active) {
      //     this._container = getDiv('', 'mapboxgl-ctrl-scale mapboxgl-ctrl a1mv__icon-map-scale_right_offset')
      //   } else {
      //     this._container = getDiv('', 'mapboxgl-ctrl-scale mapboxgl-ctrl')
      //   }
      //   // this._map.on('move', this._onMove)
      //   // this._onMove()
      //   return this._container
      // }
      const scaleControl = new mapboxgl.ScaleControl({unit: uiOptions.scaleControl.unit})
      map.addControl(scaleControl, 'bottom-left')
    }
    if (uiOptions.fullscreenControl.active) {
      map.addControl(new mapboxgl.FullscreenControl())
    }

    // eslint-disable-next-line max-len
    centerMap(map, data, mainView, center, zoom, Config.get().getCenterMapConfig())
    drawData(map, data, clientCallback, mapActionsCompleteCallback)

    if (Config.get().getFeatureConfig().displayedFeaturesChangeNotification) {
      map.on('dragend', () => {
        sendDisplayedFeaturesChangeChangeEvent(map, clientCallback, 'dragend')
      })

      map.on('zoomend', () => {
        sendDisplayedFeaturesChangeChangeEvent(map, clientCallback, 'zoomend')
      })
    }

    document.querySelectorAll('.mapboxgl-ctrl-attrib-button').forEach(function(btn,index){
      btn.setAttribute("aria-label", "attribution button " + ++index);
    });
    
  })

  const uiOpts = Config.get().getUIOptionsConfig()
  if (uiOpts.mapLayouts && uiOpts.mapLayouts.hide) {
    hideMapLayouts(map, uiOpts.mapLayouts.values)
  }

  return map
}

function getMapInitBoundsVal(data) {
  let initMapBoundsVal = { boundsVal: null, fitBoundsOptionsVal: null };
  if (!isUndefined(data.location.initMapWithBounds) && data.location.initMapWithBounds) {
    const bounds = getBounds(data.markers);
    if (!bounds.isEmpty()) {
      initMapBoundsVal.boundsVal = bounds;
      initMapBoundsVal.fitBoundsOptionsVal = {
        padding: Config.get().getCenterMapConfig().fitBoundsPadding,
        duration: Config.get().getCenterMapConfig().fitBoundsAnimationDuration
      };
    }
  }
  return initMapBoundsVal;
}

export function updateMap(
  map,
  data,
  clientCallback,
  updateMapConfig,
  mainView = null,
  mapActionsCompleteCallback
) {
  // log('updateMap', data)

  const center = getCenter(data)
  let centerMapObj = null
  if (!isEmpty(updateMapConfig) && !isEmpty(updateMapConfig.centerMap)) {
    centerMapObj = updateMapConfig.centerMap
  }
  // eslint-disable-next-line max-len
  const zoom = getZoom(isEmpty(centerMapObj) ? null : centerMapObj)
  // eslint-disable-next-line max-len

  centerMap(map, data, mainView, center, zoom, centerMapObj)

  loadIconsAndCallback(map, data, () => {
    hackCreateHighlightMarkers(map, 'markerLayer')
    const markerLayer = map.getSource('markerLayer')
    // const shapesLayer = map.getSource('shapes')
    const shapesLineLayer = map.getSource('shapesLine')
    const shapesPolygonLayer = map.getSource('shapesPolygon')
    const markersLabelsLayer = map.getSource('markersLabels')
    if (!isEmpty(markerLayer)) {
      markerLayer.setData(data.markers)
    }

    if (!isEmpty(shapesLineLayer) && !isEmpty(data.shapesLine)) {
      shapesLineLayer.setData(data.shapesLine)
    }
    if (!isEmpty(shapesPolygonLayer) && !isEmpty(data.shapesPolygon)) {
      shapesPolygonLayer.setData(data.shapesPolygon)
    }
    if (!isEmpty(markersLabelsLayer) && !isEmpty(data.markersLabels)) {
      markersLabelsLayer.setData(data.markersLabels)
    }

    mapActionsCompleteCallback(true)
  })
}

export function highlight(map, features, clientCallback = null) {
  if (!isEmpty(mapData.currentHighlightFeatures)) {
    mapData.currentHighlightFeatures.forEach((feature) => {
      map.setFeatureState(
        {source: 'markerLayer', id: feature.id},
        {highlight: false}
      )
    })
  }
  if (!isEmpty(features)) {
    mapData.currentHighlightFeatures = features
    features.forEach((feature) => {
      map.setFeatureState(
        {source: 'markerLayer', id: feature.id},
        {highlight: true}
      )
      if ((features.length > 1 && Config.get().getHighlightConfig().showPopupWhenMultiple)
      || (features.length === 1)) {
        setHighlightPopup(map, feature, clientCallback)
      } else {
        closePopups()
      }
    })

    const highlightConfig = Config.get().getHighlightConfig()
    if (highlightConfig.center && features.length === 1) {
      const flyOptions = {
        center: features[0].geometry.coordinates,
        essential: false
      }
      if (highlightConfig.zoom !== -1) {
        flyOptions.zoom = highlightConfig.zoom
      }
      flyOptions.duration = highlightConfig.flyAnimationDurationWhenSingle
      map.flyTo(flyOptions)
    } else if (highlightConfig.fitWhenMultiple && features.length > 1) {
      const bounds = getFeatureBounds(features)
      map.fitBounds(bounds,
        {
          padding: Config.get().getCenterMapConfig().fitBoundsPadding,
          duration: Config.get().getCenterMapConfig().fitBoundsAnimationDuration
        })
    }
  } else {
    closePopups()
  }
}

export function getMapImage(map) {
  const img = map.getCanvas().toDataURL('image/png')

  return img
}

export function getXYForId(map, feature) {
  return map.project(feature.geometry.coordinates)
}

export function recenter(map, data) {
  // log('recenter', mapData.lastCenterMapConfig)
  if (!isEmpty(mapData.lastCenterMapConfig)) {
    centerMap(map,
      data,
      mapData.lastCenterMapConfig.mainView,
      mapData.lastCenterMapConfig.center,
      mapData.lastCenterMapConfig.zoom,
      mapData.lastCenterMapConfig.centerMapObj)
  }
}

export function loadIconsAndCallback(map, data, callback) {
  // const markerImages = []
  data.markers.features.forEach((feature) => {
    const iconObj = {
      styleId: feature.properties.styleId,
      markerIconType: feature.properties.markerIconType,
      markerIconPath: MARKER_TYPE_WITH_CUSTOMIZED_ICON.indexOf(feature.properties.originalBaseMarkerIconType) !== -1
        ? (isEmpty(feature.properties.iconPath) ? 'none.png' : feature.properties.iconPath) : 'N/A',
      markerIconKey: feature.properties.markerIconKey,
      sdf: feature.properties.sdf
    }
    const alreadySetIconObj = findObjInArray(markerImages, 'markerIconType', iconObj.markerIconType)
    if (isEmpty(alreadySetIconObj)) {
      markerImages.push(iconObj)
    }

    if (!isUndefined(feature.properties.baseMarkerIconType)
    && !isEmpty(feature.properties.baseMarkerIconType)) {
      const iconObj1 = {
        styleId: feature.properties.styleId,
        markerIconType: feature.properties.baseMarkerIconType,
        markerIconPath: 'N/A',
        markerIconKey: feature.properties.baseMarkerIconType,
        sdf: true
      }
      const alreadySetIconObj1 = findObjInArray(markerImages, 'markerIconType', iconObj1.markerIconType)
      if (isEmpty(alreadySetIconObj1)) {
        markerImages.push(iconObj1)
      }
    }

    if(feature.properties.availableUnmatchedInd){
        var unmatchedBaseKey = feature.properties.baseMarkerIconType + '_unmatched';
         if(!markerImages.find(each => each.markerIconType === unmatchedBaseKey )){
          markerImages.push({
            styleId: feature.properties.styleId,
            markerIconType: unmatchedBaseKey,
            markerIconPath: 'N/A',
            markerIconKey: unmatchedBaseKey,
            sdf: true
          });
         }     
    }

    if (!isEmpty(feature.properties.cornerMarker)) {
      const cornerMarkerIcon = {
        styleId: feature.properties.styleId,
        markerIconType: feature.properties.cornerMarker.iconType,
        markerIconPath: 'N/A',
        markerIconKey: feature.properties.cornerMarker.iconType,
        sdf: false
      }
      const alreadySetCornerMarkerIcon = findObjInArray(markerImages, 'markerIconType', cornerMarkerIcon.markerIconType)
      if (isEmpty(alreadySetCornerMarkerIcon)) {
        markerImages.push(cornerMarkerIcon)
      }
      const cornerMarkerBgIcon = {
        styleId: feature.properties.styleId,
        markerIconType: feature.properties.cornerMarker.bgIconType,
        markerIconPath: 'N/A',
        markerIconKey: feature.properties.cornerMarker.bgIconType,
        sdf: true
      }
      const alreadySetCornerMarkerBgIcon = findObjInArray(markerImages, 'markerIconType', cornerMarkerBgIcon.markerIconType)
      if (isEmpty(alreadySetCornerMarkerBgIcon)) {
        markerImages.push(cornerMarkerBgIcon)
      }
    }
  })

  const failedLoadImages = []
  const {iconPath} = Config.get().getPathsConfig()
  Promise.all(
    markerImages
      .map((img) => new Promise((resolve, reject) => {
        let styleIdPath = ''
        if (!isEmpty(img.styleId)) {
          styleIdPath = `/${img.styleId}`
        }

        const imageURL = img.markerIconPath === 'N/A' ? `${`${iconPath}${styleIdPath}/${img.markerIconType}`}.png` : img.markerIconPath
        log(`image url: ${imageURL}`)

        // map.loadImage(`${`${iconPath}${styleIdPath}/${img.markerIconType}`}.png`, (error, res) => {
        map.loadImage(imageURL, (error, res) => {
          if (error) {
            failedLoadImages.push(error)
          } else if (!map.hasImage(img.markerIconKey)) {
            let sdf = false
            if (Config.get().getStyleConfig().useIconColor) {
              sdf = img.sdf
            }
            map.addImage(img.markerIconKey, res, {sdf})
          }
          resolve()
        })
      }))
  )
    .then((values) => {
      let success = true
      let errInfo = null
      if (failedLoadImages.length > 0) {
        success = false
        errInfo = failedLoadImages.map((err) => {
          let errMsg = err.message
          if (!isEmpty(err.url) && !isUndefined(err.url)) {
            errMsg += ` ${err.url}`
          }
          return errMsg
        }).join(' \n ')
        log(`Some Images Load Failed: \n ${errInfo}`)
      } else {
        success = false
        log('all images loaded')
      }

      callback(success, errInfo)
    })
}

export function drawData(map, data, clientCallback, mapActionsCompleteCallback) {
  drawShapes(map, data, clientCallback)
  loadIconsAndCallback(map, data, () => {
    addMarkerDataToLayer(map, data.markers)
    createClusterOnLayer(map)
    createUnclusteredMarkers(map, 'markerLayer', clientCallback)
    hackCreateHighlightMarkers(map, 'markerLayer')

    mapActionsCompleteCallback(true)
  })
}

export function drawShapes(map, data, clientCallback) {
  drawShapesPolygon(map, data, clientCallback)
  drawShapesLine(map, data, clientCallback)
  drawMarkersLabels(map, data, clientCallback)
}
export function drawShapesLine(map, data, clientCallback) {
  if (!isEmpty(data.shapesLine)) {
    map.addSource('shapesLine', {
      type: 'geojson',
      data: data.shapesLine
    })

    // 'line-dasharray': ['coalesce', ['get', 'dashArray'], [1, 0]]
    // 'line-color': ['coalesce', ['get', 'color'], '#00ffff']
    map.addLayer({
      id: LAYERS.SHAPES_LINE,
      type: 'line',
      source: 'shapesLine',
      layout: {},
      paint: {
        'line-width': ['coalesce', ['get', 'weight'], 2],
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          ['coalesce', ['get', 'highlightColor'], '#00ffff'],
          ['coalesce', ['get', 'color'], '#00ffff']
        ],
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          ['coalesce', ['get', 'opacity'], 1],
          ['coalesce', ['get', 'highlightOpacity'], 1]
        ]
      }
    })

    map.on('click', LAYERS.SHAPES_LINE, (e) => {
      if (e.features[0].properties.interactive) {
        createShapePopup(e.lngLat, map, e.features[0], clientCallback, false)
      }
    })

    map.on('mousemove', LAYERS.SHAPES_LINE, (e) => {
      if (e.features[0].properties.interactive) {
        map.getCanvas().style.cursor = 'pointer'
        if (e.features.length > 0) {
          if (mapData.hoveredFeature) {
            map.setFeatureState(
              {source: 'shapesLine', id: mapData.hoveredFeature},
              {hover: false}
            )
          }
          mapData.hoveredFeature = e.features[0].id
          map.setFeatureState(
            {source: 'shapesLine', id: mapData.hoveredFeature},
            {hover: true}
          )

          createShapePopup(e.lngLat, map, e.features[0], clientCallback, true)
        }
      }
    })

    map.on('mouseleave', LAYERS.SHAPES_LINE, () => {
      map.getCanvas().style.cursor = ''
      if (mapData.hoveredFeature) {
        map.setFeatureState(
          {source: 'shapesLine', id: mapData.hoveredFeature},
          {hover: false}
        )
        closeHoverPopups()
      }
      mapData.hoveredFeature = null
    })
  }
}

export function drawShapesPolygon(map, data, clientCallback) {
  if (!isEmpty(data.shapesPolygon)) {
    map.addSource('shapesPolygon', {
      type: 'geojson',
      data: data.shapesPolygon
    })

    // 'line-dasharray': ['coalesce', ['get', 'dashArray'], [1, 0]]
    // 'line-color': ['coalesce', ['get', 'color'], '#00ffff']
    map.addLayer({
      id: LAYERS.SHAPES_POLYGON,
      type: 'fill',
      source: 'shapesPolygon',
      layout: {},
      paint: {
        'fill-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          ['coalesce', ['get', 'highlightColor'], '#00ffff'],
          ['coalesce', ['get', 'color'], '#00ffff']
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          ['coalesce', ['get', 'opacity'], 1],
          ['coalesce', ['get', 'highlightOpacity'], 1]
        ]
      }
    })

    map.on('click', LAYERS.SHAPES_POLYGON, (e) => {
      if (e.features[0].properties.interactive) {
        createShapePopup(e.lngLat, map, e.features[0], clientCallback, false)
      }
    })

    map.on('mousemove', LAYERS.SHAPES_POLYGON, (e) => {
      if (e.features[0].properties.interactive) {
        map.getCanvas().style.cursor = 'pointer'
        if (e.features.length > 0) {
          if (mapData.hoveredFeature) {
            map.setFeatureState(
              {source: 'shapesPolygon', id: mapData.hoveredFeature},
              {hover: false}
            )
          }
          mapData.hoveredFeature = e.features[0].id
          map.setFeatureState(
            {source: 'shapesPolygon', id: mapData.hoveredFeature},
            {hover: true}
          )

          createShapePopup(e.lngLat, map, e.features[0], clientCallback, true)
        }
      }
    })

    map.on('mouseleave', LAYERS.SHAPES_POLYGON, () => {
      map.getCanvas().style.cursor = ''
      if (mapData.hoveredFeature) {
        map.setFeatureState(
          {source: 'shapesPolygon', id: mapData.hoveredFeature},
          {hover: false}
        )
        closeHoverPopups()
      }
      mapData.hoveredFeature = null
    })
  }
}

export function drawMarkersLabels(map, data, clientCallback) {
  if (!isEmpty(data.markersLabels)) {
    map.addSource('markersLabels', {
      type: 'geojson',
      data: data.markersLabels
    })

    map.addLayer({
      id: LAYERS.MARKERS_LABELS,
      type: 'symbol',
      source: 'markersLabels',
      minzoom: Config.get().getGroupConfig().textOnlyMinZoom,
      layout: {
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': ['get', 'textSize'],
        'text-offset': ['get', 'textOffset'],
        'text-field': ['get', 'textOnlyLabel'],
        'text-allow-overlap': Config.get().getGroupConfig().textOnlyAllowOverlapText
      },
      paint: {
        'text-color': ['get', 'textColor'],
        'text-halo-color': ['get', 'textHaloColor'],
        'text-halo-width': ['get', 'textHaloWidth']
      }
    })
  }
}

function addMarkerDataToLayer(map, data, name = 'markerLayer') {
  // log('addMarkerDataToLayer')

  let clusterProperties = null

  if (Config.get().shouldFilterClusterCount()) {
    const filteredClusterCount = ['==', ['get', 'addToClusterCount'], true]
    clusterProperties = {
      filteredClusterCount: ['+', ['case', filteredClusterCount, 1, 0]],
      customizedClusterCount: ['+', ['case', ['==', ['get', 'shouldAddedToPointCounter'], true], 1, 0]]
    }
  } else {
    const filteredClusterCount = true
    clusterProperties = {
      filteredClusterCount: ['+', ['case', filteredClusterCount, 1, 0]]
    }
  }

  map.addSource(name, {
    type: 'geojson',
    data,
    cluster: Config.get().shouldCluster(),
    // Max zoom to cluster points on
    clusterMaxZoom: Config.get().getGroupConfig().clusterMaxZoom,
    // Radius of each cluster when clustering points (defaults to 50)
    clusterRadius: Config.get().getGroupConfig().clusterRadius,
    clusterProperties
  })
}

function createClusterOnLayer(map, name = 'markerLayer') {
  if (!Config.get().shouldCluster()) {
    return
  }
  map.addLayer({
    id: LAYERS.CLUSTERS,
    type: 'circle',
    source: name,
    filter: ['has', 'point_count'],
    paint: {
      // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
      // with three steps to implement three types of circles:
      //   * Blue, 20px circles when point count is less than 100
      //   * Yellow, 30px circles when point count is between 100 and 750
      //   * Pink, 40px circles when point count is greater than or equal to 750
      'circle-color': [
        'step',
        ['get', 'filteredClusterCount'],
        Config.get().getClusterDrawSetting('circle-color', 'min0'),
        1,
        Config.get().getClusterDrawSetting('circle-color', 'min1'),
        100,
        Config.get().getClusterDrawSetting('circle-color', 'min100'),
        750,
        Config.get().getClusterDrawSetting('circle-color', 'min750')
      ],
      'circle-radius': [
        'step',
        ['get', 'filteredClusterCount'],
        Config.get().getClusterDrawSetting('circle-radius', 'min0'),
        1,
        Config.get().getClusterDrawSetting('circle-radius', 'min1'),
        100,
        Config.get().getClusterDrawSetting('circle-radius', 'min100'),
        750,
        Config.get().getClusterDrawSetting('circle-radius', 'min750')
      ],
      'circle-stroke-color': [
        'step',
        ['get', 'filteredClusterCount'],
        Config.get().getClusterDrawSetting('circle-stroke-color', 'min0'),
        1,
        Config.get().getClusterDrawSetting('circle-stroke-color', 'min1'),
        100,
        Config.get().getClusterDrawSetting('circle-stroke-color', 'min100'),
        750,
        Config.get().getClusterDrawSetting('circle-stroke-color', 'min750')
      ],
      'circle-stroke-width': [
        'step',
        ['get', 'filteredClusterCount'],
        Config.get().getClusterDrawSetting('circle-stroke-width', 'min0'),
        1,
        Config.get().getClusterDrawSetting('circle-stroke-width', 'min1'),
        100,
        Config.get().getClusterDrawSetting('circle-stroke-width', 'min100'),
        750,
        Config.get().getClusterDrawSetting('circle-stroke-width', 'min750')
      ]
    }
  })

  let textField = '{point_count_abbreviated}'

  if (Config.get().shouldFilterClusterCount()) {
    textField = [
      'case',
      ['==', ['get', 'filteredClusterCount'], 0],
      '',
      ['get', 'filteredClusterCount']
    ]
  }

  if(Config.get().shouldFilterClusterCount() && Config.get().getStyleConfig().customizedClusterPointCounter){
    textField =    
       ['case',
          ['==', ['get', 'customizedClusterCount'], 0],
          '',
          ['get', 'customizedClusterCount']
      ]
  }

  map.addLayer({
    id: LAYERS.CLUSTER_COUNT,
    type: 'symbol',
    source: name,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': textField,
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': Config.get().getGroupConfig().clusterTextSize,
      'text-allow-overlap': Config.get().getGroupConfig().clusterTextAllowOverlapText
    },
    paint: {
      'text-color': [
        'step',
        ['get', 'filteredClusterCount'],
        Config.get().getClusterDrawSetting('text-color', 'min0'),
        1,
        Config.get().getClusterDrawSetting('text-color', 'min1'),
        100,
        Config.get().getClusterDrawSetting('text-color', 'min100'),
        750,
        Config.get().getClusterDrawSetting('text-color', 'min750')
      ]
    }
  })

  map.on('mouseenter', LAYERS.CLUSTERS, () => {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', LAYERS.CLUSTERS, () => {
    map.getCanvas().style.cursor = ''
  })

  map.on('click', 'clusters', (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['clusters']
    })
    const clusterId = features[0].properties.cluster_id
    map.getSource(name).getClusterExpansionZoom(
      clusterId,
      (err, zoom) => {
        if (err) return

        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom
        })
      }
    )
  })
}

function hackCreateHighlightMarkers(map, name = 'markerLayer') {
  if (map.getLayer(LAYERS.UNCLUSTERED_POINT_HIGHLIGHT_BG)) {
    map.removeLayer(LAYERS.UNCLUSTERED_POINT_HIGHLIGHT_BG)
  }

  const highlightStyle = Config.get().getHighlightStyle()
  if (highlightStyle === HIGHLIGHT_STYLES.BG_CIRCLE
    || highlightStyle === HIGHLIGHT_STYLES.POPUP_AND_BG_CIRCLE) {
    map.addLayer({
      id: LAYERS.UNCLUSTERED_POINT_HIGHLIGHT_BG,
      type: 'circle',
      source: name,
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-radius': ['get', 'highlightCircleRadius'],
        'circle-opacity': [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          Config.get().getHighlightConfig().highlightCircleColorOpcaity,
          0
        ],
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          Config.get().getHighlightConfig().highlightCircleColor,
          '#3840c7'
        ],
        'circle-stroke-color': Config.get().getHighlightConfig().highlightCircleStrokeColor,
        'circle-stroke-width': [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          ['get', 'highlightCircleStrokeWidth'],
          0
        ],
        'circle-translate': Config.get().getHighlightConfig().hackHighlightCircleOffset
      }
    })

    map.moveLayer(LAYERS.UNCLUSTERED_POINT_HIGHLIGHT_BG, LAYERS.UNCLUSTERED_POINT_BG)
  }
}

function createUnclusteredMarkers(map, name = 'markerLayer', clientCallback) {
  map.addLayer({
    id: LAYERS.UNCLUSTERED_POINT_BG,
    type: 'symbol',
    source: name,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': ['case',
                       ['boolean', ['get','availableUnmatchedInd'], false], ['concat',['get','baseMarkerIconType'],'_unmatched'], 
                       ['get', 'baseMarkerIconType'] ],
      'icon-size': ['get', 'baseIconSize'],
      'icon-allow-overlap': Config.get().allowOverlap(),
      'icon-offset': ['get', 'baseMarkerOffset']
    },
    paint: {
      'icon-color': ['get', 'baseIconColor']
    }
  })

  map.addLayer({
    id: LAYERS.UNCLUSTERED_POINT,
    type: 'symbol',
    source: name,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': ['get', 'markerIconKey'],
      'icon-size': ['get', 'iconSize'],
      'icon-offset': ['get', 'mainMarkerOffset'],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': ['get', 'textSize'],
      'text-offset': ['get', 'textOffset'],
      'text-field': ['get', 'markerIconStaticLabel'],
      'icon-allow-overlap': Config.get().allowOverlap(),
      'text-allow-overlap': Config.get().allowOverlapText()
    },
    paint: {
      'icon-color': ['get', 'iconColor'],
      'icon-halo-color': ['get', 'iconHaloColor'],
      'icon-halo-width': ['get', 'iconHaloWidth'],
      'text-color': ['get', 'textColor'],
      'text-halo-color': ['get', 'textHaloColor'],
      'text-halo-width': ['get', 'textHaloWidth']
    }
  })

  const cornerMarkerProps = ['get', 'cornerMarker']

  map.addLayer({
    id: LAYERS.UNCLUSTERED_POINT_CORNER_MARKER_BG,
    type: 'symbol',
    source: name,
    filter: ['has', 'cornerMarker'],
    layout: {
      'icon-image': ['get', 'bgIconType', cornerMarkerProps],
      'icon-size': ['get', 'bgSize', cornerMarkerProps],
      'icon-offset': ['get', 'bgOffset', cornerMarkerProps],
      'icon-allow-overlap': Config.get().allowOverlap()
    },
    paint: {
      'icon-color': ['get', 'bgColor', cornerMarkerProps]
    }
  })

  map.addLayer({
    id: LAYERS.UNCLUSTERED_POINT_CORNER_MARKER,
    type: 'symbol',
    source: name,
    filter: ['has', 'cornerMarker'],
    layout: {
      'icon-image': ['get', 'iconType', cornerMarkerProps],
      'icon-size': ['get', 'iconSize', cornerMarkerProps],
      'icon-offset': ['get', 'iconOffset', cornerMarkerProps],
      'icon-allow-overlap': Config.get().allowOverlap()
    },
    paint: {
      'icon-color': ['get', 'iconColor']
    }
  })

  let mouseHandlerLayer = LAYERS.UNCLUSTERED_POINT_BG
  if (!Config.get().getMarkerConfig().mouseHandlersOnBaseMarker) {
    mouseHandlerLayer = LAYERS.UNCLUSTERED_POINT
  }

  // When a click event occurs on a feature in
  // the unclustered-point layer, open a popup at
  // the location of the feature, with
  // description HTML from its properties.
  map.on('click', mouseHandlerLayer, (e) => {
    clientCallback(getActionObj(ACTION_TYPE.FEATURE_CLICK, e.features[0].properties))
    const coordinates = e.features[0].geometry.coordinates.slice()

    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
    }
    var currentFeature = e.features[0];  
    currentFeature.properties.markerUniqueIdentifier && Config.get().mapConfig && Config.get().mapConfig.extendFunc && 
         Config.get().mapConfig.extendFunc(currentFeature.properties.markerUniqueIdentifier); 
    createPopup(map, e.features[0], clientCallback, false, false)
  })

  map.on('mouseenter', mouseHandlerLayer, (e) => {
    map.getCanvas().style.cursor = 'pointer'
    clientCallback(getActionObj(ACTION_TYPE.FEATURE_HOVER_ENTER, e.features[0].properties))

    // map.getCanvas().style.cursor = 'pointer'
    if (e.features.length > 0) {
      if (mapData.hoveredFeature) {
        map.setFeatureState(
          {source: name, id: mapData.hoveredFeature},
          {hover: false}
        )
      }
      mapData.hoveredFeature = e.features[0].id || e.features[0].properties.id
      map.setFeatureState(
        {source: name, id: mapData.hoveredFeature},
        {hover: true}
      )

      createPopup(map, e.features[0], clientCallback, false, true)
    }
  })
  map.on('mouseleave', mouseHandlerLayer, (e) => {
    map.getCanvas().style.cursor = ''
    clientCallback(getActionObj(ACTION_TYPE.FEATURE_HOVER_LEAVE, {}))

    // map.getCanvas().style.cursor = ''
    if (mapData.hoveredFeature) {
      map.setFeatureState(
        {source: name, id: mapData.hoveredFeature},
        {hover: false}
      )
      closeHoverPopups()
    }
    mapData.hoveredFeature = null
  })
}

function getZoom(centerMapObj) {
  let zoom = Config.get().getZoomConfig().defaultZoom
  if (!isEmpty(centerMapObj) && !isUndefined(centerMapObj.zoom)) {
    zoom = centerMapObj.zoom
  }

  return zoom
}

function getCenter(data, centerMapSetting = null) {
  // USA center 41.850033, -87.6500523

  let center = {lat: 41.850033, lon: -87.6500523}
  let centerType = null
  if (!isEmpty(centerMapSetting) && !isEmpty(centerMapSetting.type)) {
    centerType = centerMapSetting.type
  }

  if (isSingleMarker(data) && (centerType === CENTER_MAP_TYPES.CENTER)) {
    center = getSingleMarkerCenter(data)
  } else if (!isEmpty(data.location.center) && data.location.center.lat !== 0) {
    center = data.location.center
  }

  return center
}

function centerMap(map, data, mainView, center, zoom, centerMapObj = null) {
  // console.log('centerMap', data, mainView, center, zoom, centerMapObj)

  const saveForRecenter = getValue(() => centerMapObj.saveForRecenter, true)
  if (saveForRecenter) {
    mapData.lastCenterMapConfig.mainView = mainView
    mapData.lastCenterMapConfig.center = center
    mapData.lastCenterMapConfig.zoom = zoom
    mapData.lastCenterMapConfig.centerMapObj = centerMapObj
  }

  let centerType = null
  if (!isEmpty(centerMapObj) && !isEmpty(centerMapObj.type)) {
    centerType = centerMapObj.type
  }

  if (centerType === CENTER_MAP_TYPES.NONE) {
    return
  }

  const faciltyBounds = getFacilityBounds(data.shapesLine)
  // log('mainView', mainView)
  // do not check center type any more
  if (!isEmpty(mainView) && (centerType === CENTER_MAP_TYPES.MAIN_VIEW || isEmpty(centerType))) {
    const mainViewBounds = getMapBoundsForMarkers(mainView.geometry.coordinates[0])
    map.fitBounds(
      [[mainViewBounds.sw.lng, mainViewBounds.sw.lat], [mainViewBounds.ne.lng, mainViewBounds.ne.lat]],
      {
        padding: Config.get().getCenterMapConfig().fitBoundsPadding,
        duration: Config.get().getCenterMapConfig().fitBoundsAnimationDuration
      }
    )
    // eslint-disable-next-line max-len
  } else if (!isEmpty(faciltyBounds) && (centerType === CENTER_MAP_TYPES.MAIN_VIEW || isEmpty(centerType))) {
    map.fitBounds([[faciltyBounds.sw.lng, faciltyBounds.sw.lat], [faciltyBounds.ne.lng, faciltyBounds.ne.lat]],
      {
        padding: Config.get().getCenterMapConfig().fitBoundsPadding,
        duration: Config.get().getCenterMapConfig().fitBoundsAnimationDuration
      })
  } else if (((!isUndefined(data.location) && !isEmpty(data.location.center) && data.location.center.lat !== 0 && data.location.center.lon !== 0) || isSingleMarker(data))
  && (centerType === CENTER_MAP_TYPES.CENTER || isEmpty(centerType))) {
    map.setZoom(zoom)
    if (isSingleMarker(data)) {
      map.setCenter(getSingleMarkerCenter(data))
    } else {
      map.setCenter(center)
    }
  } else if (hasMarkers(data) && (centerType === CENTER_MAP_TYPES.FIT || isEmpty(centerType))) {
    if(!data.markers.features.some( element => element.properties.useForCentering)){
      return;
    }
    const bounds = getBounds(data.markers)
    map.fitBounds(bounds,
      {
        padding: Config.get().getCenterMapConfig().fitBoundsPadding,
        duration: Config.get().getCenterMapConfig().fitBoundsAnimationDuration
      })
  }
}

function getFacilityBounds(allFeatures) {
  if (isEmpty(allFeatures) || isEmpty(allFeatures.features)) {
    return null
  }
  for (const eachFeature of allFeatures.features) {
    if (eachFeature.properties.shapeId === 'area' && eachFeature.properties.shapeStyleId === 'facilityBounds') {
      return getMapBoundsForMarkers(eachFeature.geometry.coordinates[0])
    }
  }
  return null
}

function getBounds(data) {
  const bounds = new mapboxgl.LngLatBounds()

  if (isEmpty(data) || isEmpty(data.features)) {
    return bounds
  }

  let useForCenteringCtr = 0
  // eslint-disable-next-line no-restricted-syntax
  for (const feature of data.features) {
    if (!isUndefined(feature.properties.useForCentering) && feature.properties.useForCentering) {
      useForCenteringCtr += 1
    }
  }

  data.features.forEach((feature) => {
    if (useForCenteringCtr > 0) {
      if (!isUndefined(feature.properties.useForCentering) && feature.properties.useForCentering) {
        bounds.extend(feature.geometry.coordinates)
      }
    } else {
      bounds.extend(feature.geometry.coordinates)
    }
  })

  return bounds
}

export function getFeatureBounds(features) {
  const bounds = new mapboxgl.LngLatBounds();

  if (isEmpty(features)) {
    return bounds;
  }
  features.forEach((feature) => {
    bounds.extend(feature.geometry.coordinates);
  });

  return bounds;
}

function hasMarkers(data) {
  let markers = false

  if (!isEmpty(data) && !isEmpty(data.markers) && !isEmpty(data.markers.features)) {
    markers = true
  }

  return markers
}

function isSingleMarker(data) {
  let single = false

  if (hasMarkers(data) && data.markers.features.length === 1) {
    single = true
  }

  return single
}

function getSingleMarkerCenter(data) {
  return {
    lat: data.markers.features[0].geometry.coordinates[1],
    lon: data.markers.features[0].geometry.coordinates[0]
  }
}

export function togglePerspective(map) {
  if (isEmpty(map)) {
    return
  }

  const pc = Config.get().getPerspectiveConfig()
  const providerConfig = Config.get().getProviderConfig()

  if (!mapData.showingPerspective) {
    mapData.showingPerspective = true
    map.setPitch(pc.pitch)
    map.setBearing(pc.bearing)
    map.addSource(SOURCES.PERSPRCTIVE, {
      type: 'raster-dem',
      url: pc.sourceURL,
      tileSize: pc.tileSize,
      maxzoom: pc.maxZoom
    })

    if (providerConfig.ver >= 2000) {
      // add the DEM source as a terrain layer with exaggerated height
      map.setTerrain({source: SOURCES.PERSPRCTIVE, exaggeration: pc.exaggeration})

      // add a sky layer that will show when the map is highly pitched
      map.addLayer({
        id: LAYERS.SKY,
        type: 'sky',
        paint: {
          'sky-type': pc.skyType,
          'sky-atmosphere-sun': pc.skyAtmosphereSun,
          'sky-atmosphere-sun-intensity': pc.skyAtmosphereSunIntesity
        }
      })
    }
  } else {
    mapData.showingPerspective = false
    if (providerConfig.ver >= 2000) {
      map.removeLayer(LAYERS.SKY)
      map.setTerrain(null)
    }
    map.removeSource(SOURCES.PERSPRCTIVE)
    map.setPitch(0)
    map.setBearing(0)
  }
}

export function getMapBoundsForMarkers(markers) {
  const bounds = {}
  let minLat = 100000
  let minLng = 100000
  let maxLat = -100000
  let maxLng = -100000

  // eslint-disable-next-line no-restricted-syntax
  for (const marker of markers) {
    if (marker[1] < minLat) {
      // eslint-disable-next-line prefer-destructuring
      minLat = marker[1]
    }
    if (marker[0] < minLng) {
      // eslint-disable-next-line prefer-destructuring
      minLng = marker[0]
    }
    if (marker[1] > maxLat) {
      // eslint-disable-next-line prefer-destructuring
      maxLat = marker[1]
    }
    if (marker[0] > maxLng) {
      // eslint-disable-next-line prefer-destructuring
      maxLng = marker[0]
    }
  }
  bounds.nw = {
    lat: minLat,
    lng: minLng
  }
  bounds.se = {
    lat: maxLat,
    lng: maxLng
  }
  bounds.ne = {
    lat: maxLat,
    lng: maxLng
  }
  bounds.sw = {
    lat: minLat,
    lng: minLng
  }

  return bounds
}

function hideMapLayouts(map, values) {
  map.on('style.load', () => {
    try {
      const nameArrays = values.split(',')
      for (let arrayIndex = 0; arrayIndex < nameArrays.length; arrayIndex++) {
        if (nameArrays[arrayIndex]) {
          const toBeHideLayout = map.getLayer(nameArrays[arrayIndex])
          if (toBeHideLayout) {
            map.setLayoutProperty(nameArrays[arrayIndex], 'visibility', 'none')
          }
        }
      }
    } catch (error) {
    }
  })
}

function sendDisplayedFeaturesChangeChangeEvent(map, clientCallback, eventName) {
  const features = map.queryRenderedFeatures({layers: [LAYERS.UNCLUSTERED_POINT]})
  const baseBounds = map.getBounds()
  // eslint-disable-next-line no-underscore-dangle
  const bounds = {ne: baseBounds._ne, sw: baseBounds._sw}
  const center = map.getCenter()
  clientCallback(
    getActionObj(
      ACTION_TYPE.DISPLAYED_FEATURES_CHANGE,
      {
        displayedFeatures: features,
        bounds,
        center,
        eventName
      },
      null,
      false
    )
  )
}

function setHighlightPopup(map, feature, clientCallback = null) {
  const highlightStyle = Config.get().getHighlightStyle()
  if (highlightStyle === HIGHLIGHT_STYLES.POPUP
    || highlightStyle === HIGHLIGHT_STYLES.POPUP_AND_BG_CIRCLE) {
    createPopup(map, feature, clientCallback, true)
  }
  if (feature.properties.baseMarkerIconType === 'slip') {
    createPopup(map, feature, clientCallback, false)
  }
}

export function createPopup(map, feature, clientCallback, isHighlight = false, isHover = false) {
  const {showPopupOnMarkerFeatureHover} = Config.get().getPopupConfig()
  const {showPopupOnMarkerFeatureClick} = Config.get().getPopupConfig()
  if ((isHover && showPopupOnMarkerFeatureHover) || (!isHover && showPopupOnMarkerFeatureClick)) {
    if (isPopupRenderable(feature)) {
      if (isHover) {
        closeHoverPopups()
      } else {
        closePopups()
      }

      let styleAppender = '-popup'
      let closeButton = true
      if (isHighlight) {
        styleAppender += '-highlight'
        closeButton = false
        if (Config.get().getHighlightConfig().showPopupCloseButton) {
          closeButton = true
        }
      }
      if (isHover) {
        styleAppender += '-hover'
        closeButton = false
      }

      const popup = new mapboxgl.Popup({
        focusAfterOpen: false ,
        className: Config.get().getMainStyle() + styleAppender + 
        (feature.properties.markerUniqueIdentifier ? " additionalPopupStyle": "" ) ,
        offset: 25,
        closeButton
      })
        .setLngLat(feature.geometry.coordinates)
        // eslint-disable-next-line max-len
        .setDOMContent(getPopupContentByStyle(feature, clientCallback, Config.get().getMainStyle(), isHighlight, isHover))
        .addTo(map)

      if (isHover) {
        mapData.currentHoverPopup = popup
      } else {
        mapData.currentPopup = popup
      }
    }
  }
}

function createShapePopup(coordinates, map, feature, clientCallback, isHover = false) {
  const {showPopupOnShapeFeatureHover} = Config.get().getPopupConfig()
  const {showPopupOnShapeFeatureClick} = Config.get().getPopupConfig()
  if ((isHover && showPopupOnShapeFeatureHover) || (!isHover && showPopupOnShapeFeatureClick)) {
    if (isHover) {
      closeHoverPopups()
    } else {
      closePopups()
    }

    let styleAppender = '-popup'
    let closeButton = true
    if (isHover) {
      styleAppender += '-hover'
      closeButton = false
    }

    const popup = new mapboxgl.Popup({
      className: Config.get().getMainStyle() + styleAppender,
      offset: 5,
      closeButton
    })
      .setLngLat(coordinates)
      // eslint-disable-next-line max-len
      .setDOMContent(getShapePopupContentByStyle(feature, clientCallback, Config.get().getMainStyle(), isHover))
      .addTo(map)

    if (isHover) {
      mapData.currentHoverPopup = popup
    } else {
      mapData.currentPopup = popup
    }
  }
}

export function closePopups() {
  if (!isEmpty(mapData.currentPopup) && mapData.currentPopup.isOpen()) {
    mapData.currentPopup.remove()
    mapData.currentPopup = null
  }
}

export function closeHoverPopups() {
  if (!isEmpty(mapData.currentHoverPopup) && mapData.currentHoverPopup.isOpen()) {
    mapData.currentHoverPopup.remove()
    mapData.currentHoverPopup = null
  }
}

/** *********** Map change style code ************ */
function forEachLayer(map, text, cb) {
  map.getStyle().layers.forEach((layer) => {
    if (layer.id !== text) return

    cb(layer)
  })
}

function addLayerGroup(map, layerId, lgArr) {
  const layer = map.getLayer(layerId)
  if (!isUndefined(layer) && !isEmpty(layer)) {
    lgArr.push(layerId)
  }
}
// Changing the map base style
export function changeMapStyle(map, style, data, callback) {
  // log('changeMapStyle', style.id)
  const savedLayers = []
  const savedSources = {}
  const layerGroups = []
  const providerConfig = Config.get().getProviderConfig()

  // addLayerGroup(map, LAYERS.SHAPES, layerGroups)
  addLayerGroup(map, LAYERS.SHAPES_LINE, layerGroups)
  addLayerGroup(map, LAYERS.SHAPES_POLYGON, layerGroups)
  addLayerGroup(map, LAYERS.MARKERS_LABELS, layerGroups)
  addLayerGroup(map, LAYERS.CLUSTERS, layerGroups)
  addLayerGroup(map, LAYERS.CLUSTER_COUNT, layerGroups)
  addLayerGroup(map, LAYERS.UNCLUSTERED_POINT_HIGHLIGHT_BG, layerGroups)
  addLayerGroup(map, LAYERS.UNCLUSTERED_POINT_BG, layerGroups)
  addLayerGroup(map, LAYERS.UNCLUSTERED_POINT, layerGroups)
  addLayerGroup(map, LAYERS.UNCLUSTERED_POINT_CORNER_MARKER_BG, layerGroups)
  addLayerGroup(map, LAYERS.UNCLUSTERED_POINT_CORNER_MARKER, layerGroups)

  if (providerConfig.ver >= 2000) {
    addLayerGroup(map, LAYERS.SKY, layerGroups)
  }

  // log('layerGroups', layerGroups)

  layerGroups.forEach((layerGroup) => {
    forEachLayer(map, layerGroup, (layer) => {
      if (layer.source && map.getSource(layer.source)) {
        savedSources[layer.source] = map.getSource(layer.source).serialize()
      }
      savedLayers.push(layer)
    })
  })

  if (mapData.showingPerspective) {
    if (map.getSource(SOURCES.PERSPRCTIVE)) {
      savedSources[SOURCES.PERSPRCTIVE] = map.getSource(SOURCES.PERSPRCTIVE).serialize()
    }
  }

  let needToHighlightFeature = false
  if (!isEmpty(mapData.currentHighlightFeatures)
  && !isUndefined(mapData.currentHighlightFeatures)) {
    mapData.currentHighlightFeatures.forEach((feature) => {
      const highlightState = map.getFeatureState(
        {
          source: 'markerLayer',
          id: feature.id
        }
      )
      if (highlightState.highlight) {
        needToHighlightFeature = true
      }
    })
  }

  map.setStyle(style.vectorStyle)

  map.once('style.load', () => {
    const pc = Config.get().getPerspectiveConfig()
    loadIconsAndCallback(map, data, () => {
      Object.entries(savedSources).forEach(([id, source]) => {
        if (!map.getSource(id)) {
          map.addSource(id, source)
          if (id === SOURCES.PERSPRCTIVE) {
            if (providerConfig.ver >= 2000) {
              map.setTerrain({source: SOURCES.PERSPRCTIVE, exaggeration: pc.exaggeration})
            }
          }
        }
      })

      savedLayers.forEach((layer) => {
        const layer1 = map.getLayer(layer.id)
        if (isUndefined(layer1) || isEmpty(layer1)) {
          try {
            map.addLayer(layer)
          } catch (error) {
            // console.log(e)
          }
        }
      })

      if (needToHighlightFeature) {
        mapData.currentHighlightFeatures.forEach((feature) => {
          map.setFeatureState(
            {source: 'markerLayer', id: feature.id},
            {highlight: true}
          )
        })
      }

      callback(style.id)
    })
  })
}
